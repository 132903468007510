.content {
  border: 2px solid #e3e8ef;
  box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
  background: #fefefe;
}

.title {
  font-size: 24px;
  line-height: 152%;
  letter-spacing: 2.4px;
  font-weight: 600;
  text-transform: uppercase;
  color: #192f44;
}

.text {
  font-size: 18px;
  line-height: 1.5;
  color: #202939;
}

.partnersTitle {
  font-size: 16px;
  line-height: 150%;
  color: #121926;
  opacity: 0.7;
}

.partnerLogo {
  opacity: 75%;
  mix-blend-mode: difference;
  object-fit: contain;
}

@media (max-width: 1439px) {
  .container {
    padding: 96px 40px 80px;
    display: flex;
    justify-content: center;
  }

  .content {
    padding: 72px 40px 56px;
    max-width: 880px;
  }

  .textContainer {
    max-width: 400px;
  }
}

@media (max-width: 959px) {
  .container {
    padding: 72px 40px 56px;
  }

  .content {
    padding: 40px;
    max-width: 572px;
  }

  .title {
    color: font-size;
  }

  .textContainer {
    max-width: 100%;
  }
}

@media (max-width: 567px) {
  .container {
    padding: 0;
  }

  .content {
    padding: 40px 16px;
    box-shadow: none;
    border-radius: 0;
    border: none;
    max-width: 100%;
    background: var(--Linear, linear-gradient(180deg, #fff 26%, #e6f0ff 100%));
  }

  .textContainer {
    row-gap: 24px;
  }

  .text {
    font-size: 16px;
  }

  .smLogoContainer {
    padding: 16px 0 24px;
  }

  .simulmediaTextLogo {
    width: 252px;
  }

  .partnersTitle {
    display: block;
    padding-bottom: 24px;
  }
}
